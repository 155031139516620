#tms-solution-page #tms-solution-page-header .tms-solution-page-header-card {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px;
    position: relative;
  }
  
  #tms-solution-page
    #tms-solution-page-header
    .tms-solution-page-header-card::before {
    content: "";
    background: url(../../../public/img/ico-before.png) no-repeat;
    background-size: 100% 100%;
    width: 30%;
    height: 30%;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  #tms-solution-page
    #tms-solution-page-header
    .tms-solution-page-header-card::after {
    content: "";
    background: url(../../../public/img/ico-after.png) no-repeat;
    background-size: 100% 100%;
    width: 30%;
    height: 30%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  
  #giai-phap .option,
  #tinh-nang .option {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f0f0;
    border: 0.5px solid #faad1e;
    padding: 1rem;
    text-align: center;
    margin-bottom: 1rem;
    height: 10vh;
    cursor: pointer;
    font-weight: 700;
    border-radius: 1rem;
  }
  
  #giai-phap .option.active,
  #tinh-nang .option.active {
    background: #faad1e;
    color: #fff;
    font-weight: bolder;
  }
  
  #giai-phap .option.active img,
  #tinh-nang .option.active img {
    filter: brightness(0) invert(1);
  }
  
#dms-solution-page #dms-solution-page-header .dms-solution-page-header-card {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px;
  position: relative;
}

#dms-solution-page
  #dms-solution-page-header
  .dms-solution-page-header-card::before {
  content: "";
  background: url(../../../public/img/ico-before.png) no-repeat;
  background-size: 100% 100%;
  width: 30%;
  height: 30%;
  position: absolute;
  left: 0;
  top: 0;
}

#dms-solution-page
  #dms-solution-page-header
  .dms-solution-page-header-card::after {
  content: "";
  background: url(../../../public/img/ico-after.png) no-repeat;
  background-size: 100% 100%;
  width: 30%;
  height: 30%;
  position: absolute;
  right: 0;
  bottom: 0;
}
